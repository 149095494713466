<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>权限设置</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
<!-- 			<el-row class="catopt">
				<el-col :span="12">
					<el-button type="primary" class="add-test" @click="add">添加</el-button>
				</el-col>
			</el-row> -->
			<el-table :data="companyData" border stripe style="width: 100%">
				<!--                <el-table-column
                    prop="id"
                    label="ID">
                </el-table-column> -->
				<el-table-column label="角色">
					一级管理员
				</el-table-column>
				<el-table-column prop="company" label="管理部门">
				</el-table-column>
				<!--                <el-table-column
                    prop="branch_level"
                    label="权限等级">
                </el-table-column> -->
				<el-table-column label="权限菜单范围">
					<template slot-scope="scope">
						{{formatpowerlist(scope.row.powers)}}
					</template>
				</el-table-column>
				<el-table-column prop="account" label="账号">
				</el-table-column>
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="status" label="状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							active-value="open" inactive-value="close"
							@change="adminStatus(scope.row.status,scope.row.id)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="editadmin(scope.row)">权限设置</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
		<el-dialog title="权限编辑" :visible.sync="poserVisible" width="780px">
			<div>
				<el-transfer :titles="['未拥有权限', ' 赋予该级的权限']" :button-texts="['加入左侧', '加入右侧']" v-model="powerlistChange" :data="powerlist"></el-transfer>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="info" @click="poserVisible = false">取消</el-button>
				<el-button type="primary" @click="editPower">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		add_team,
		team_list,
		team_status,
		editpower
	} from '../api/team'
	export default {
		data() {
			return {
				poserVisible:false,
				powerlist:[],
				powerlistChange:[],
				companyData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				dialogVisible: false,
				company: '',
				branch_level: 1,
				account: '',
				password: '',
				name: '',
				editid:null
			};
		},
		computed: {},
		methods: {
			editadmin(d){
				let add = `[{"key":1,"label":"数据概览","path":"/index"},{"key":2,"label":"可视大屏","path":"/visualizations"},{"key":3,"label":"设备管理","path":"/equipment"},{"key":4,"label":"监测记录","path":"/monttoring"},{"key":5,"label":"预警记录","path":"/warning"},{"key":6,"label":"定位地图","path":"/location"},{"key":7,"label":"管理员权限","path":"/power"},{"key":8,"label":"部门管理","path":"/department"},{"key":9,"label":"人员管理","path":"/personnel"},{"key":10,"label":"操作日志","path":"/log"}]`
				this.editid = d.id;
				this.poserVisible = true;
				let powerlist = JSON.parse(d.powers);
				this.powerlist=[{key:1,label:"数据概览",path:"/index",disabled:false},{key:2,label:"可视大屏",path:"/visualizations",disabled:false},{key:3,label:"设备管理",path:"/equipment",disabled:false},
				{key:4,label:"监测记录",path:"/monttoring",disabled:false},{key:5,label:"预警记录",path:"/warning",disabled:false},{key:6,label:"定位地图",path:"/location",disabled:false},{key:7,label:"管理员权限",path:"/power",disabled:false},
				{key:8,label:"部门管理",path:"/department",disabled:false},{key:9,label:"人员管理",path:"/personnel",disabled:false},{key:10,label:"操作日志",path:"/log",disabled:false},{key:11,label:"团队管理",path:"/team",disabled:false}];
				this.powerlist=JSON.parse(d.allpowers);
				for(let i in this.powerlist){
					if([1,11].includes(this.powerlist[i].key)){
						this.powerlist[i].disabled = true;
					}
				}
				this.powerlistChange = [];
				powerlist.forEach((item) =>{
					// if(![1,11].includes(item.key)){
						this.powerlistChange.push(item.key)
					// }
				})
			},
			formatpowerlist(d) {
				if(d){
					let list = JSON.parse(d)
					let arr = [];
					for(let i in list){
						arr.push(list[i].label)
					}
					return arr.join('、');
				}else{
					return "";
				}
			},
			add() {
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false;
				this.account = '';
				this.password = '';
				this.name = '';
			},
			editPower() {
				let arr = [];
				this.powerlist.forEach(item=>{
					this.powerlistChange.forEach(itemc=>{
						if(itemc == item.key){
							arr.push(item)
						}
					})
				})
				editpower({id:this.editid,power:arr}).then(res=>{
					if (res.status == 200) {
						this.poserVisible = false;
						this.companyList()
						return this.$message.success('编辑成功！')
					}
					return this.$message.error('编辑失败！')
				})
			},
			companyList() {
				team_list(this.pagenum, this.pagesize).then(res => {
					if (res.status == 200) {
						this.companyData = res.data.companys;
						this.total = res.data.total
					}
				})
			},
			adminStatus(value, id) {
				team_status(value, id).then(res => {
					if (res.status == 200) {
						this.$message.success('设置成功')
					}
				})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.companyList();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.companyList();
			},
		},
		created() {
			this.companyList()
		},
	};
</script>
<style lang='less' scoped>
	.catopt {
		margin-bottom: 10px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
	}
</style>
